@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Roboto+Slab:wght@400;500;700&display=swap');

@layer base {
  html {
    @apply text-default font-sans;
  }
}

body {
  margin: 0;
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-shadow {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4), 2px 2px 1px rgba(0, 0, 0, 0.25), 3px 3px 1px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);;
}

.navigation-shadow:hover {
  text-shadow:
    -1px -1px 0 #c02673,
    1px -1px 0 #c02673,
    -1px 1px 0 #c02673,
    1px 1px 0 #c02673;
}